import React from 'react'
import Stylesets from '../components/stylesets/Stylesets'
import Layout from '../components/layout/Layout'

const Page = ({pageContext}) => {
    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >
            <Stylesets/>
        </Layout>
    )
}

export default Page