import React, {PureComponent} from 'react'
import cx from 'classnames'
import styles from './Stylesets.module.scss'
import whitelist from './whitelist.json'

const STYLE_SETS = [
    'SS1',
    'SS2',
    'SS4',
    'SS5',
    'SS7',
    'SS9',
    'SS11',
]

const toPaddedHexString = (number, length = 4) => {
    const string = number.toString(16)
    return '0'.repeat(length - string.length) + string
}

const getRandomCharIndex = (chars, indices) => {
    const index = Math.floor(Math.random() * chars.length)
    if (!indices.includes(index)) return index
    else return getRandomCharIndex(chars, indices)
}

class Stylesets extends PureComponent {
    constructor() {
        super()
        this.state = {...whitelist}
    }

    onClick(charCode, styleset) {
        if (this.state[styleset].includes(charCode)) {
            const codes = this.state[styleset].filter(code => code !== charCode)
            this.setState(state => ({[styleset]: codes}))
        } else {
            this.setState(state => ({[styleset]: [...this.state[styleset], charCode]}))
        }
    }

    render() {
        const text = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const chars = text.split('')

        return (
            <div>
                <table className={styles.Table}>
                    <tbody>
                    <tr>
                        <td>Standard</td>
                        {
                            chars.map((char, index) => {
                                const code = toPaddedHexString(char.charCodeAt(0))
                                return (
                                    <td className={styles.Main}
                                        key={index}>

                                        <span className={styles.Char}> {char}</span>
                                        <span className={styles.Info}>{code}</span>
                                    </td>
                                )
                            })
                        }
                    </tr>

                    {
                        STYLE_SETS.map(styleset => (
                            <tr key={styleset}>
                                <td>{styleset}</td>
                                {
                                    chars.map((char, index) => {
                                        const code = toPaddedHexString(char.charCodeAt(0))
                                        const classes = cx(styles.Main, {[styles.IsActive]: this.state[styleset] && this.state[styleset].includes(code)})
                                        return (
                                            <td className={classes}
                                                key={index}
                                                onClick={this.onClick.bind(this, code, styleset)}>

                                                <span className={cx(styles.Char, styles[styleset])}> {char}</span>
                                                <span className={styles.Info}>{code}</span>

                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <textarea readOnly={true} className={styles.Text} value={JSON.stringify(this.state)}/>
            </div>
        )
    }
}

export default Stylesets